<template>
  <div id="app" class="container">
    <div class="head">
      <h1>LexiAura Dictionary</h1>

      <div class="search-bar">
        <input type="text" v-model="searchWord" placeholder="Enter a word">
        <button @click="searchDefinition">Search</button>
      </div>
    </div>

    <div v-if="definition" class="definition">

      <h2>{{ definition.word }}</h2>

      <p v-if="definition.phonetic">{{ definition.phonetic }}</p>

      <ul v-for="meaning in definition.meanings" :key="meaning.partOfSpeech">

        <li><b>{{ meaning.partOfSpeech }}:</b>

          <ul v-for="def in meaning.definitions" :key="def.definition">
            <li>{{ def.definition }}</li>
            <li v-if="def.synonyms.length > 0"><b>Synonyms:</b> {{ def.synonyms.join(', ')}},</li>
            <li v-if="def.example"><b>Example:</b> {{ def.example }}</li>
          </ul>

        </li>

      </ul>

    </div>

    <p v-else-if="errorMessage" class="error-message">{{ errorMessage }}</p>

  </div>


  <footer class="footer">
    <div class="footer-container">
      <div class="footer-section">
        <h4>LEARN</h4>
        <ul>
          <li><a href="">New Words</a></li>
          <li><a href="">Dictionary API</a></li>
          <li><a href="">Accessibility</a></li>
        </ul>
      </div>
      <div class="footer-section">
        <h4>DEVELOP</h4>
        <ul>
          <li><a href="">Help</a></li>
          <li><a href="">Double-Click Lookup</a></li>
          <li><a href="">Cambridge English</a></li>
        </ul>
      </div>
      <div class="footer-section">
        <h4>ABOUT</h4>
        <ul>
          <li><a href="">Search Widgets</a></li>
          <li><a href="">Word of the Year 2022</a></li>
          <li><a href="">License Data</a></li>
          <li><a href="">Cookies Settings</a></li>
        </ul>
      </div>
      <div class="footer-section">
        <h4>MORE INFORMATION</h4>
        <ul>
          <li><a href="">Word of the Year 2023</a></li>
          <li><a href="">Cookies and Privacy</a></li>
          <li><a href="">Word of the Year 2024</a></li>
          <li><a href="">Corpus</a></li>
          <li><a href="">Terms of Use</a></li>
        </ul>
      </div>
    </div>
    <div class="footer-copyright">
      <p>&copy; {{ new Date().getFullYear() }} LexiAura Dictionary</p>
    </div>
  </footer>

</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {
      searchWord: '',
      definition: null,
      errorMessage: '',
    };
  },
  methods: {
    async searchDefinition() {
      this.errorMessage = '';
      try {
        const response = await axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${this.searchWord}`);
        const definitionData = response.data[0]; // Assuming first result

        this.definition = {
          word: definitionData.word,
          phonetic: definitionData.phonetic,
          meanings: definitionData.meanings.map(meaning => ({
            partOfSpeech: meaning.partOfSpeech,
            definitions: meaning.definitions.map(def => ({
              definition: def.definition,
              synonyms: def.synonyms,
              example: def.example || '', // Handle optional example
            })),
          })),
        };

      } catch (error) {
        this.errorMessage = 'Word not found.';
        console.error('Error fetching definition:', error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  font-family: sans-serif;
  margin: 0 auto;
  max-width: 100%;
  padding: 20px;
  background-image: url("./assets/1-rose-bg.jpg");
  background-size: cover;
  background-position: center;
}

.head {
  margin: 10px auto;
  max-width: 100%;
  padding: 40px 180px;
  background-color: rgba(216, 135, 135, 0.5);
  background-size: cover;
  background-position: center;
}

.container h1 {
  color: #ece1e1;
  font-size: 4rem;
}

.search-bar {
  display: flex;
  width: 800px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-bar input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
}

.search-bar button {
  background-color: rgba(246, 116, 116, 0.5);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.search-bar button:hover {
  background-color: rgba(201, 141, 141, 0.5);
}

.definition {
  border: 0px solid #ddd;
  padding: 30px 25px;
  margin-bottom: 20px;
  background-color: rgba(218, 182, 182, 0.5);
  font-size: 20px;
}

.definition h2 {
  font-size: 3rem;
}

.definition p, .definition li {
  font-size: 16px;
}

.footer {
  background-color: rgba(216, 135, 135, 0.5);
  padding: 80px 20px;
  justify-content: space-between;
  align-items: flex-start;
  font-family: sans-serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.footer-section {
  flex: 1 0 20%;
  padding: 10px;
}

.footer-section h3 {
  font-size: 1rem;
  margin-bottom: 10px;
  color: black;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section li {
  margin-bottom: 5px;
  font-size: 14px;
}

.footer-section a {
  color: black;
  text-decoration: none;
}

.footer-section a:hover {
  color: #695c5c;
}

.footer-copyright {
  text-align: center;
  color: black;
  font-size: 0.8rem;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }
  .head {
    padding: 20px;
  }
  .search-bar {
    width: 100%;
  }
  .footer-section {
    flex: 1 0 100%;
  }
}


</style>