import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';

const app = createApp(App);

// Configure axios for API requests
app.config.globalProperties.$http = axios.create({
  baseURL: 'https://api.dictionaryapi.dev/api/v3/synonyms/',
});

app.config.globalProperties.$httpGame = axios.create({
  baseURL: 'https://api.example.com/game/', // Your game API (replace with actual URL)
});

app.mount('#app');

const style = document.createElement('style');
style.textContent = `
  body {
    margin: 0;
  }
`;
document.head.appendChild(style);